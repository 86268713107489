<template>
  <div class="photo-gallery-card shadow-sm">
    <div class="photo-type ">
        <img :src="absoluteUrl(photoType.value)" class="photo-type-img w-100">
    </div>
    <div class="photo-type-name p-1">
        <h6 class="mb-1 small">{{photoType.name}}</h6>
    </div>
  </div>
</template>

<script>
export default {
    props:{
        photoType:{
            type:Object,
            required: true
        },
    }
    
}
</script>

<style>
.photo-gallery-card .photo-type {
    position: relative;
}
.photo-gallery-card .photo-type{
    border: 3px solid #edecec;
    background: var(--bs-light);
    transition: border-width 0.3s ease-in;
}
.photo-gallery-card  .photo-type.selected .color-row{
    border-color: var(--bs-success);
}
.photo-gallery-card .photo-type.selected{
    border-color: var(--bs-success);
}
</style>