

<template>
    <div v-if="requirements.length" class="row gy-3">
      <div class="col-12">
        <h4 class="mb-0 text-primary">Requirements</h4>
        <hr class="mt-1"/>
        <div  v-for="(requirement, rIndex) of requirements" :key="rIndex"
          class="mb-4 ps-4 position-relative">
            <span class="position-absolute start-0">{{rIndex+1}}</span>
            <div v-if="['pro_input','pro_select','pro_textarea'].includes(requirement.type)">
                <h6 class="mb-1">{{requirement.title}}</h6>
                <p>{{requirement.answer}}</p>
            </div>
            <div v-else-if="requirement.type == 'photo_gallery'" class="">
                <h6 class="mb-1">{{requirement.title}}</h6>
                <div class="row mb-3 g-3">
                    <div v-for="(photoType, ptIndex) of requirement.answer" :key="ptIndex"
                    class="col-md-4 col-lg-3 col-6 text-center">
                        <photo-gallery-card :photoType="photoType"/>
                    </div>
                </div>
            </div>
            <div v-else-if="requirement.type == 'color_gallery'" class="" >
                <h6 class="mb-1">{{requirement.title}}</h6>
                <div class="row mb-3 g-3">
                <div v-for="(colorType, clIndex) in requirement.answer" :key="clIndex"
                class="col-md-4 col-lg-3 col-6 text-center">
                    <color-gallery-card :colorType="colorType"/>
                </div>
                </div>
            </div>
            <div v-else-if="requirement.type == 'pro_checkbox'" class="" >
                <h6 class="mb-1">{{requirement.title}}</h6>
                <div v-if="requirement.answer">
                    {{requirement.answer.map((i)=> i.name).join(", ")}}
                </div>
            </div>
                <div v-else-if="requirement.type == 'file_uploader'" class="" >
                <h6 class="mb-1">{{requirement.title}}</h6>
                <order-attachments columnClass="col-lg-3"
                  v-if="requirement.answer && requirement.answer.length"
                  :files="requirement.answer" />
            </div>
            <div v-else>
                <h6 class="mb-1">{{requirement.title}}</h6>
                <p>{{requirement.answer ? requirement.answer : "none" }}</p>
            </div>
        </div>
      </div>
    </div>
</template>

<script>
import OrderAttachments from './OrderAttachments.vue'
import ColorGalleryCard from "@/components/cards/ColorGalleryCard.vue"
import PhotoGalleryCard from "@/components/cards/PhotoGalleryCard.vue"

export default {
  components:{
    ColorGalleryCard,
    PhotoGalleryCard,
    OrderAttachments,
  },
  computed: {
    order(){
      return this.$store.state.orderList.order
    },
    requirements(){
      return this.order.cart.requirements
    },
    files(){
      return this.order.sample_files
    },
  }
}
</script>



