<template>
    <div class="order-messages">
        <div class="card shadow-sm">
            <div class="card-body px-2 py-2 border-bottom">
                <div class="py-2">
                    <div class="row justify-content-center">
                        <div class="col-12 text-center">
                            <h6 class="mb-0 fw-light">Conversations for Order: #{{order.number}}</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body p-2">
                <message-window ref="orderLogPSWindow" :messages="messageData">
                    <template v-slot:footer>
                        <form class="pt-2">
                            <textarea  v-model="form.message" class="form-control mb-0"
                                rows="4" placeholder="Send message" name="message-edit"></textarea>
                            <div class="pt-2">
                                <b-button  @click.prevent="modalUploadFiles = true" variant="light" 
                                class="btn-icon waves-effect waves-light me-3">
                                <i class="fas fa-paperclip"></i></b-button>
                                <button @click.prevent="sendMessage()" type="button" class="btn btn-primary">
                                    Send Message
                                </button>
                            </div>
                        </form>
                    </template>
                </message-window>
            </div>
        </div>
        <div>
            <b-modal centered id="modal-2" v-model="modalUploadFiles" title="Upload Files" header-close-variant="primary" title-class="font-18 text-primary" hide-footer>
                <upload-attachment @scrollMessagesUp="resetForm(); scrollMessageAreaUp() "/>
            </b-modal>
        </div>
    </div>
</template>

<script>

import MessageWindow from "./components/MessageWindow.vue"
import UploadAttachment from "./components/UploadAttachment.vue"

export default {
    name: 'order-message',
	components: {
        MessageWindow, UploadAttachment
	},
	data() {
		return {
            modalUploadFiles:false,
            form:{
                message: ""
            },
		}
	},
	computed: {
        order(){
            return this.$store.state.orderList.order
        },
        messageData(){
            return this.order.messages
        },
	},
	methods: {
        sendMessage() {
            if (!this.form.message) { this.alertError("Please type something"); return;}
            const formData = new FormData()
            formData.append('data', JSON.stringify(this.form))
            this.$store.dispatch("changeLoaderValue", true)
            this.$http.post(`/order-messages/${this.order.id}/create`, formData)
            .then((response) => {
                if(response.data.success){
                    this.$store.dispatch("changeLoaderValue")
                    this.$store.commit(`orderList/ADD_MESSAGE`,response.data.data.message)
                    this.resetForm();
                    this.scrollMessageAreaUp();
                }
            })
        },
        scrollMessageAreaUp(){
            setTimeout(()=>{
                this.$refs.orderLogPSWindow.scrollMessageAreaUp()
            },1000)
        },
        resetForm(){
            this.modalUploadFiles = false;
            this.form.message =  "";
        },
	},
    mounted(){
        this.scrollMessageAreaUp()
    },

}
</script>

