

<template>
<div>
  <h4 class="mb-0 text-primary">Features</h4>
  <hr class="mt-1"/>
  <ul class="row gx-3 gy-2 gy-sm-3 list-unstyled">
    <li class="col-sm-6 col-lg-4">
      <span> ✔️</span> <span>{{calculateDeliveryTime(orderPackage.delivery_time)}} Delivery</span>
    </li>
    <li class="col-sm-6 col-lg-4">
      <span> ✔️</span> <span>{{orderPackage.revisions}} Revisions</span>
    </li>
    <template v-for="(attribute, index) in attributes">
      <li class="col-sm-6 col-lg-4" v-if="attribute.type == 'check' && orderPackage.attributes[attribute.id] == 'supported'"  :key="index">
        <span> ✔️</span> <span>{{attribute.name}} </span>
      </li>
      <li class="col-sm-6 col-lg-4" v-if="attribute.type == 'input' && orderPackage.attributes[attribute.id]"  :key="index">
        <span>✔️</span>
        <span> {{orderPackage.attributes[attribute.id]}} {{attribute.name}} </span>
      </li>
    </template>
  </ul>
</div>
</template>

<script>
export default {
    name: "feature-list",
    props: {
      cart: {
        type: Object,
        required: true,
      },
    },
    computed:{
      attributes(){
        return this.cart.attributes;
      },
      orderPackage(){
        return this.cart.package;
      }
    }
}
</script>
