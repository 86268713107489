<template>
   <div class="color-gallery-card shadow-sm">
        <div class="color-row d-flex">
            <div v-for="(swatch, sIndex) in getColorType" :key="sIndex"
            :style="{'background-color': swatch, 'width': `${100/getColorType.length}%`}"
            class="color-type-block">
            </div>
        </div>
        <div class="color-type-name p-1">
            <h6 class="mb-0 small">{{colorType.name}}</h6>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        colorType:{
            type:Object,
            required: true
        },
    },
    computed:{
        getColorType(){
            return this.colorType.value.split(',')
        }
    }
}
</script>

<style>
.color-gallery-card{
    cursor: pointer;
}

.color-gallery-card .color-row{
    border-radius: 8px;
    overflow: hidden;
    border: 3px solid transparent;
    transition: border-width 0.3s ease-in;
}
.color-gallery-card .isCheck{
    position: absolute;
    top:0;
    right: 5px;
    transition: opacity 0.3s ease-in;
    opacity: 0;
}

.color-gallery-card.selected .color-row{
    border-color: var(--bs-success);
}

.color-gallery-card.selected .isCheck{
    opacity: 1;
}

.color-gallery-card {
    position: relative;
}

.color-gallery-card .color-type-block{
    height: 60px;
}
@media (max-width: 567px) {
    .color-gallery-card .color-type-block {
        height: 50px;
    }
}
</style>