

<template>
    <div class="row g-3 gy-3">
        <div class="col-12 col-lg-6" v-for="(file, index) in list" :key="index">
            <div class="card m-0 shadow-none border text-start">
                <a
                 :href="absoluteUrl(`/download?file_path=${file.path}`)"
                  :download="file.name" style="color:inherit;" class="downloadable p-2 font-sm">
                <div class="row align-items-center">
                    <div class="col-12 mb-1" v-if="isImageFile(file.extension)">
                        <img class="w-100 img-fluid" :src="absoluteUrl(file.path)" :alt="file.name">
                    </div>
                    <div class="col-auto">
                        <span style="padding:2px 3px; font-size:12px;" class="bg-primary text-white rounded text-uppercase">{{file.extension}}</span>
                    </div>
                    <div class="col text-end">
                        <span class="text-muted"><i class="fa fa-download"></i> {{file.size}}</span>
                    </div>
                </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "message-files",
    props: {
        list: {
            type: Array,
            required: true,
        },
    },
}
</script>
