<template>
    <div>
        <div v-if="!isLoading">
            <div v-if="order.status == 'draft'" >
              <b-alert :show="true"  variant="warning">
                <h5 class="alert-heading">Saved as draft!</h5>
                <p class="mb-0">
                  <span class="me-2">This order was saved as draft. Proceed to make payment so that this order can be processed.</span>
                </p>
                <hr class="my-2">
                <div class="d-flex">
                  <a class="btn btn-sm btn-primary me-2" :href="absoluteUrl(`/projects/${order.id}/payment`)">Pay Now!</a>
                  <a class="btn btn-sm btn-danger" @click.prevent="deleteDraft()" href="#">Delete Draft!</a>
                </div>
              </b-alert>
            </div>
            <div v-if="order.status == 'delivered'" class="alert alert-soft-primary" role="alert">
                Hello {{activeUser.first_name}}, your order was delivered! Kindly check and make comment(s) for revision. 
                If you are satisfied with the delivery, please kindly complete order.
                <a @click.prevent="completeOrder()" href="#" class="btn text-success btn-sm btn-link"> Mark Completed! </a>
            </div>
            <b-tabs nav-class="nav-tabs nav-bordered">
                <b-tab title="Overview" :active="activeTab === 0">
                    <order-overview />
                </b-tab>
                <b-tab title="Messages" :active="activeTab === 1" :lazy="activeTab != 1">
                    <order-messages />
                </b-tab>
            </b-tabs>
        </div>
        <is-loading v-if="isLoading" />
    </div>
</template>

<script>

import OrderMessages from "@/components/order/OrderMessages.vue"
import OrderOverview from "@/components/order/OrderOverview.vue"
import IsLoading from '@/components/IsLoading.vue'

export default {
    name: 'order-show',
	components: {
        OrderMessages,
        OrderOverview,
        IsLoading
	},
	data() {
		return {
            isLoading: false
		}
	},
    watch: {
        "$route":"fetchOrder"
    },
    computed: {
        activeUser(){
            return this.$store.state.activeUser;
        },
        order(){
            return this.$store.state.orderList.order
        },
        activeTab(){
            return this.$route.query.tab ? +this.$route.query.tab : 1;
        },
    },
	methods: {
        fetchOrder(){
            this.isLoading = true;
            this.$store.dispatch("orderList/fetchOrder", this.$route.params.orderId)
            .then((response) => {
                if(response.data.success){
                    this.isLoading = false;
                }else{
                   this.$router.push({path: '/error-404'})
                }
            })
        },
        deleteDraft() {
            Swal.fire({
                title: "Are you sure?",
                text: "You are about to delete this draft order",
                reverseButtons:true,
                showCancelButton: true,
                confirmButtonColor: "#f1556c",
                cancelButtonColor: "#111",
                confirmButtonText: "Delete",
            }).then((result) => {
                if (result.value) {
                this.$store.dispatch("changeLoaderValue",true)
                this.$http.delete(`/orders/${this.order.id}/delete`)
                .then(response => {
                    this.$store.dispatch("changeLoaderValue")
                    if(response.data.success){
                        this.$router.push({path:'/orders'})
                    }
                })
                }
            });
        },
        completeOrder(){
            Swal.fire({
                icon:"info",
                title: "Are you sure?",
                text: "You are about to mark this order completed.",
                showCancelButton: true,
                confirmButtonColor: '#00afef',
                confirmButtonText: 'Yes, Proceed',
                showLoaderOnConfirm: true,
            preConfirm: () => {
                return this.$http.post(`/orders/${this.order.id}/mark-completed`)
                .then(response => {
                    if (!response.data.success) {
                        throw new Error(response.data.error ? response.data.error : response.statusText)
                    }
                    this.$store.dispatch("orderList/fetchOrder",this.order.id)
                    return response;
                })
                .catch(error => {
                    Swal.showValidationMessage(
                    `Request failed: ${error}`
                    )
                });
            },
            allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
                if (result.value) {
                    if(result.value.data.success){
                        Swal.fire({
                            icon: 'success',
                            text: `${result.value.data.success}`,
                        });
                    }else{
                        Swal.fire({
                            icon: 'error',
                            text: `${result.value.data.error}`,
                            showConfirmButton: false,
                            timer: 2500,
                        });
                    }
                }
		    })
        },
	},
    created(){
        this.fetchOrder();
    }

}
</script>

