<template>
  <div class="card">
    <div class="card-body">
      <div class="order-details-body">
        <div class="row mb-4">
          <div class="col-12">
            <h4 class="">Item Purchased: <span class="text-muted"> <a :href="absoluteUrl(`/pricing?get_slug=${product.slug}`)"
             class="cursor-pointer" target="_blank"> {{product.name}} </a> -  {{orderPackage.name}} package </span></h4>
          </div>
          <div class="col-12 col-md-4">
            <h6 class="">Order Number: <span class="text-muted">#{{order.number}}  </span></h6>
          </div>
          <div class="col-6 col-md-4">
            <h6 class="">Status: <span class="text-muted text-capitalize">{{order.status}}  </span></h6>
          </div>
          <div class="col-6 col-md-4">
            <h6 class="">Total price: <span class="text-muted text-capitalize"> ${{order.amount | money_format}}  </span></h6>
          </div>
          <div class="col-12">
            <p class="mb-0">Date: <span class="text-muted">{{order.created_at | date(true)}}  </span></p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div>
              <feature-list :cart="cart" />
            </div>
            <div v-if="cart.addons && cart.addons.length" class="mt-5">
              <addons />
            </div>
            <div class="mt-5">
              <requirement-list />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Addons from "./components/Addons.vue"
import RequirementList from "./components/RequirementList.vue"
import FeatureList from "./components/FeatureList.vue"

export default {
  components: {
    Addons, FeatureList, RequirementList
  },
  computed: {
    order(){
      return this.$store.state.orderList.order
    },
    cart(){
      return this.order.cart
    },
    orderPackage(){
      return this.cart.package
    },
    product(){
      return this.cart.product
    },
  },
}

</script>

<style type="text/css" scoped>

    sub{font-size: 12px;}
    @media(max-width: 580px){
      .order-details-header{
        margin-bottom: 1.5em;

      }
    }

</style>
