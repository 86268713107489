<template>
  <div v-if="addons && addons.length" class="">
    <h4 class="mb-0 text-primary">Extra Services</h4>
    <hr class="mt-2">
    <div class="row">
      <div class="col-12">
        <ul class="list-unstyled">
          <template v-for="(addon, adIndex) in addons">
              <li :key="adIndex"
                class="list__item p-2 mb-2 shadow-sm d-flex align-items-center justify-content-between bg-light d-block">
                <div>
                  <h5 class="my-0" >{{addon.name}}</h5>
                  <p class="mb-0"> {{addon.description}}</p>
                </div>
                <h5 class="my-0">${{addon.charge | money_format}}</h5>
              </li>
          </template>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    order(){
      return this.$store.state.orderList.order
    },
    addons(){
      return this.order.cart.addons
    },
  }
}
</script>
