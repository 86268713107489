
<template>
  <div>
    <div class="row">
      <div class="col-12">
        <vue-dropzone id="dropzone" ref="fileUploadDrop" class="" 
        :use-custom-slot="true" :maxFiles="5">
          <div class="dz-message my-0 needsclick">
            <i class="h1 text-primary mdi mdi-plus"></i>
            <h4>Upload Files or Drop files</h4>
            <span class="text-primary font-13">
              <em>maximum of 5 files</em>
            </span>
          </div>
        </vue-dropzone>
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-12">
        <div class="text-center">
          <button type="button" @click.prevent="sendAttachment()" class="btn btn-primary mt-2">
            <i class="mdi mdi-content-save"></i> Upload
          </button>
      </div>
      </div>
    </div>
</div>
</template>

<script>
import VueDropzone from '@/components/VueDropzone.vue'

export default {
  components: { VueDropzone },
  name: "upload-attachment",
  methods: {
    sendAttachment() {
      if(!this.collectFiles().length){ this.alertError("Please a file is required"); return;}
      const formData = new FormData()
      this.collectFiles().forEach((item, index) => formData.append('file_'+index, item))
      this.$store.dispatch("changeLoaderValue", true)
      this.$http.post(`/order-messages/${this.$route.params.orderId}/send-files`, formData)
      .then((response) => {
          this.$store.dispatch("changeLoaderValue")
          if(response.data.success){
            this.$store.commit(`orderList/ADD_MESSAGE`, response.data.data.message)
            this.$store.dispatch("orderList/fetchOrder",this.$route.params.orderId)
            this.resetForm();
            this.scrollMessageAreaUp();
          }
      })
    },
    scrollMessageAreaUp(){
      this.$emit('scrollMessagesUp');
    },
    collectFiles(){
      let arrafile = [];
      if(this.$refs.fileUploadDrop){
        arrafile = [...this.$refs.fileUploadDrop.getAcceptedFiles()];
      }
      return arrafile
    },
    resetForm() {
      this.$refs.fileUploadDrop.removeAllFiles();
    },
  }
}

</script>

